//contains all the CSS that handles the layout, such as the container and any grid systems.

*,
::after,
::before {
  box-sizing: border-box;
}

main {
  display: flex;
}

.main-content {
  flex-grow: 1;
  padding: 30px;
  width: 100%;

  .header-logo {
    width: 150px;

    svg {
      fill: #505050;
    }
  }

  @include sm {
    margin-top: 80px;
  }
}

.action-bar,
.filter-bar {
  margin-bottom: 1.5rem;
}

.page-content {
  .section {
    margin-bottom: 2rem;

    &__title {
      font-weight: 300;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

footer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;

  &__content {
    width: 100%;

    .login-form-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .login-form {
        width: 500px;
        background-color: #f9f9f9;
        padding: 50px;
        border-radius: 15px;

        .logo-wrapper {
          margin-bottom: 1rem;

          .login-logo {
            width: 300px;

            svg {
              fill: #505050;
            }

            @include xs {
              width: 80%;
              margin: auto;
            }
          }
        }

        .input-wrapper {
          button {
            margin-top: 1rem;
          }

          > * {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        @include xs {
          width: 100%;
          padding: 50px 30px;
        }
      }
    }
  }
}

.sidebar {
  width: 92px;
  transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.expanded {
    width: 250px;
    transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  &__content {
    width: 92px;
    overflow-x: hidden;
    position: relative;
    transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;

    &.expanded {
      width: 250px;
      transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    &.scrollbar {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: #fff, $alpha: 0.4);
        border-radius: 9999px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .expand-button {
      display: flex;
      width: 32px;
      justify-content: center;
      margin-bottom: 50px;
      transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

      &.expand-button-expanded {
        width: unset;
        justify-content: flex-end !important;
        transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      }
    }

    .profile {
      justify-content: flex-start;
      margin-bottom: 40px;
    }

    .navigation ul .link,
    .footer {
      a,
      button {
        font-size: 1.1rem;

        svg {
          font-size: 2rem;
          margin-right: 30px;
        }
      }
    }
  }
}

.topbar {
  &__content {
    padding: 10px 30px;

    .topbar-logo {
      width: 150px;
      height: 40px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      svg {
        fill: white;
      }

      @include xs {
        width: 120px;
        height: 32px;
      }
    }
  }
}

.mobile-sidebar {
  width: 300px;

  &__content {
    .profile {
      justify-content: center;
      margin-bottom: 50px;
    }

    .navigation ul .link,
    .footer {
      a,
      button {
        font-size: 1rem;

        svg {
          font-size: 1.8rem;
          margin-right: 15px;
        }
      }
    }
  }
}

.sidebar,
.mobile-sidebar {
  &__content {
    padding: 30px;
    background-color: $primary-color !important;
    border-right: 0 !important;
    display: flex;
    justify-content: space-between;

    .profile .avatar {
      transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    .navigation {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        .link {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .footer {
      margin-top: 50px;

      button {
        font-family: "Open Sans", sans-serif;
        background-color: transparent;
        border: 0;
        padding: 0;
      }
    }

    .navigation ul .link,
    .footer {
      a,
      button {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        font-weight: 800;
        white-space: nowrap;

        svg {
          fill: white;
        }
      }
    }
  }
}
