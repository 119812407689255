.no-site-service-wrapper {
  background-color: #efefef;
  padding: 50px;
  height: 300px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  border-radius: 15px;
  text-align: center;
}
