//contained within this file are all your resets, variables, mixins, and any utility classes.
body {
  color: #505050 !important;
  font-family: "Open Sans", sans-serif !important;
  margin: 0;
}

$primary-color: #8ed200;
$secondary-color: #505050;

// React Big Calendar
.calendar {
  position: relative;
  overflow-x: auto;

  .overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 6;
    background-color: rgba($color: #fff, $alpha: 0.8);
  }

  .rbc-calendar {
    min-height: 900px;
    min-width: 1300px;

    .toolbar-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      .label-date {
        text-transform: uppercase;
        font-size: 2rem;
        color: $secondary-color;

        .month {
          font-weight: 800;
        }
      }
    }

    .rbc-month-view {
      border: 0;

      .rbc-header {
        border-bottom: 0;
        text-transform: uppercase;
        font-weight: 800;
        color: $secondary-color;
        letter-spacing: 3px;

        & + .rbc-header {
          border-left: 0;
        }
      }

      .rbc-month-row + .rbc-month-row {
        border-top: 0;
      }

      .rbc-day-bg {
        background-color: #f9f9f9;
        border-radius: 10px;
        margin: 5px;
        transition: all 500ms ease-in-out;
        -webkit-transition: all 500ms ease-in-out;

        & + .rbc-day-bg {
          border-left: 0;
        }

        &.rbc-off-range-bg {
          background-color: #dadada;
        }
      }

      .rbc-date-cell {
        padding: 12px 15px 5px !important;
        font-weight: 700;
        color: $primary-color;

        &.rbc-current {
          color: darken($color: $primary-color, $amount: 8);
        }
      }

      .rbc-today {
        background-color: rgba(lighten($color: $primary-color, $amount: 5), $alpha: 0.2) !important;
      }

      .rbc-row-segment {
        padding: 0 12px 4px !important;

        .rbc-show-more {
          background: transparent;
          color: $secondary-color;
          text-decoration: none;
          font-weight: 800;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.rbc-overlay {
  border-radius: 10px;

  .rbc-overlay-header {
    padding: 10px 15px;
    color: $secondary-color;
    font-weight: 800;
    border-bottom: 0;
  }

  > * + * {
    margin-top: 4px !important;
  }

  .rbc-event-content {
    padding: 2px 5px;
  }
}

.rbc-calendar,
.rbc-overlay {
  .rbc-event {
    // background-color: darken($color: $primary-color, $amount: 5) !important;
    transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;

    .rbc-event-content {
      font-size: 14px;
      font-weight: 700;
    }

    &:hover {
      // background-color: darken($color: $primary-color, $amount: 20) !important;
      transition: all 500ms ease-out;
      -webkit-transition: all 300ms ease-out;
    }
  }
}

// Toast
.toast {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;

  &__body {
    padding: 15px !important;
    border-radius: 10px !important;
  }
}
