//anything reusable such as buttons, navbars, cards etc.
.page-title {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
  color: $secondary-color;
}

.dialog {
  padding: 30px;

  &__header {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    line-height: 1;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;

    > * {
      margin-right: 1rem !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.card {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 15px 30px;
  box-shadow: 0 4px 15px 0 rgba(136, 152, 170, 0.25) !important;

  &__title {
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 1rem;

    .servicing {
      color: $primary-color;
      font-style: italic;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  &__actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  &.no-action .card__content {
    margin-bottom: 1rem;
  }
}

.dialog,
.card {
  &__content {
    > * {
      margin-bottom: 1.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.input,
.detail {
  &__label {
    font-size: 0.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__field em {
    color: #c4c4c4 !important;
  }
}

//Filter Bar Inputs
.select-control {
  border: 1px solid #dadada !important;
  border-radius: 5px !important;
  padding: 7px 26px 7px 12px !important;
  font-size: 0.8rem !important;
  max-width: 150px;
}

.datepicker-field {
  position: relative;

  .datepicker-input {
    min-width: 125px;
    min-height: 35px;
    border: 1px solid #dadada !important;
    border-radius: 5px !important;
    padding: 7px 26px 7px 12px !important;
    font-size: 0.8rem !important;
  }

  .clear-btn {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;

  &__label {
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
  }

  &__field {
    margin-right: 5px;
  }
}

.table,
.paper {
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);

  &__header {
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem 1.5rem;
  }
}

.table {
  position: relative;
  border: 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    padding: 1rem 1.5rem;
    border-top: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;

    .filters {
      display: flex;

      .filter {
        display: flex;
        align-items: center;
        margin-right: 30px;

        &__label {
          font-size: 0.8rem;
          font-weight: 700;
          margin-right: 1rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__content {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      text-align: left;
      white-space: nowrap;
      border-collapse: collapse;
      border-spacing: 0px !important;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      thead,
      tbody {
        th,
        td {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
      thead {
        background-color: #ececec;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;

        th {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 0.6rem;
          text-transform: uppercase;
          border-left: 0;
          border-right: 0;

          &.employees-column {
            width: 160px;
          }

          .sortable {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              margin-left: 10px;
              fill: #888;
            }
          }
        }
      }

      tbody {
        tr {
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;

          &:hover {
            background: darken($color: #fff, $amount: 5%);
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
          }

          &:last-child {
            td {
              border-bottom: 0;
            }
          }

          td {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            font-size: 0.8rem;
            font-weight: 700;
            border-bottom: 1px solid #dadada;

            .avatar {
              margin-right: 10px;
            }
          }

          td.service_type-column {
            max-width: 180px;

            > * {
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          td.employees-column {
            .employee-avatar {
              margin-left: -0.8rem;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          td.actions-column {
            text-align: right;
            button {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          td.color-column {
            width: 25px;
            padding-right: 0;

            .color {
              width: 25px;
              height: 25px;
              box-shadow: 0 3px 10px 2px rgba(136, 152, 170, 0.25) !important;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  &__footer {
    padding: 0.5rem 1.5rem;

    .pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__result {
        font-size: 0.8rem;
      }
    }
  }
}

.error {
  padding: 10px 0;
  background: lighten($color: #dc4848, $amount: 5);
  font-style: italic;
  font-size: 14px;
  color: white;
  text-align: center;
  border-radius: 9999px;
}

.danger-btn {
  color: #fff !important;
  background-color: #dc4848 !important;

  &:hover {
    background-color: darken($color: #dc4848, $amount: 15) !important;
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}
