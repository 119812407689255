$xs: 599px;
$sm: 959px;
$lg: 1919px;
$xl: 1920px;

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}
